export const newEssay = {
    "text":[
       {
          "para":{
             "text":"I've spent most of my life as an anti-vegetable carboholic. For years, processed snack foods ruled the kitchen kingdom of my household and animal products outnumbered plant-based offerings.",
             "errorTypes":[
                "syntax",
                "syntax"
             ],
             "errorId":[
                "17_0",
                "17_0"
             ],
             "cardId":null
          },
          "sentencesTokens":[
             {
                "tokens":[
                   {
                      "ind":0,
                      "text":"I",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":1,
                      "text":"'ve ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":2,
                      "text":"spent ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "lived",
                         "worked",
                         "trained"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":3,
                      "text":"most ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":4,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":5,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":6,
                      "text":"life ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":7,
                      "text":"as ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":8,
                      "text":"an ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":9,
                      "text":"anti",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":10,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":11,
                      "text":"vegetable ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":12,
                      "text":"carboholic",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":13,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"I've spent most of my life as an anti-vegetable carboholic.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":14,
                      "text":"For ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":15,
                      "text":"years",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":16,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":17,
                      "text":"processed ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":18,
                      "text":"snack ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":19,
                      "text":"foods ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":20,
                      "text":"ruled ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":21,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":22,
                      "text":"kitchen ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":23,
                      "text":"kingdom ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":24,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":25,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":26,
                      "text":"household ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":27,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":28,
                      "text":"animal ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":29,
                      "text":"products ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":30,
                      "text":"outnumbered ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":31,
                      "text":"plant",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":32,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":33,
                      "text":"based ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":34,
                      "text":"offerings",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":35,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"For years, processed snack foods ruled the kitchen kingdom of my household and animal products outnumbered plant-based offerings.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             }
          ]
       },
       {
          "para":{
             "text":"My transformation began with my mom's cancer diagnosis. My mom went on a 100% whole food plant-based diet. I fully embraced this new eating philosophy to show my support. Eager to figure out the whole \"vegan\" thing, the two of us started binge-watching health documentaries such as \"What the Health\" and \"Forks Over Knives\". We read all the books by the featured doctors like \"The China Study\" and \"How Not To Die\". I became entranced by the world of nutritional science and how certain foods could help prevent cancer or boost metabolism.",
             "errorTypes":[
                
             ],
             "errorId":[
                
             ],
             "cardId":null
          },
          "sentencesTokens":[
             {
                "tokens":[
                   {
                      "ind":36,
                      "text":"My ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":37,
                      "text":"transformation ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":38,
                      "text":"began ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "coincided",
                         "begins",
                         "ended"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":39,
                      "text":"with ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":40,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":41,
                      "text":"mom",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":42,
                      "text":"'s ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":43,
                      "text":"cancer ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":44,
                      "text":"diagnosis",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":45,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"My transformation began with my mom's cancer diagnosis.",
                   "errorTypes":[
                      "flow"
                   ],
                   "errorId":[
                      "7_0"
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":46,
                      "text":"My ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":47,
                      "text":"mom ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":48,
                      "text":"went ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "relies",
                         "feeds",
                         "works"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":49,
                      "text":"on ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":50,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":51,
                      "text":"100",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":52,
                      "text":"% ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":53,
                      "text":"whole ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":54,
                      "text":"food ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":55,
                      "text":"plant",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":56,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":57,
                      "text":"based ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":58,
                      "text":"diet",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":59,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"My mom went on a 100% whole food plant-based diet.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":60,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":61,
                      "text":"fully ",
                      "errorTypes":[
                         "flow"
                      ],
                      "errorId":[
                         "22_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":62,
                      "text":"embraced ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":63,
                      "text":"this ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":64,
                      "text":"new ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":65,
                      "text":"eating ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":66,
                      "text":"philosophy ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":67,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":68,
                      "text":"show ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "gain",
                         "lend",
                         "earn"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":69,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":70,
                      "text":"support",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":71,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"I fully embraced this new eating philosophy to show my support.",
                   "errorTypes":[
                      "flow"
                   ],
                   "errorId":[
                      "50_0"
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":72,
                      "text":"Eager ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":73,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":74,
                      "text":"figure ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":75,
                      "text":"out ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":76,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":77,
                      "text":"whole ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":78,
                      "text":"\"",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":79,
                      "text":"vegan",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":80,
                      "text":"\" ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":81,
                      "text":"thing",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "2"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":82,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":83,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":84,
                      "text":"two ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":85,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":86,
                      "text":"us ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":87,
                      "text":"started ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "begin",
                         "began",
                         "enjoy"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":88,
                      "text":"binge",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":89,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":90,
                      "text":"watching ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "eating",
                         "consuming",
                         "fueled"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":91,
                      "text":"health ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":92,
                      "text":"documentaries ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":93,
                      "text":"such ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":94,
                      "text":"as ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":95,
                      "text":"\"",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":96,
                      "text":"What ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":97,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":98,
                      "text":"Health",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":99,
                      "text":"\" ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":100,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":101,
                      "text":"\"",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":102,
                      "text":"Forks ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":103,
                      "text":"Over ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":104,
                      "text":"Knives",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":105,
                      "text":"\"",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":106,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"Eager to figure out the whole \"vegan\" thing, the two of us started binge-watching health documentaries such as \"What the Health\" and \"Forks Over Knives\".",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":107,
                      "text":"We ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":108,
                      "text":"read ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":109,
                      "text":"all ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":110,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":111,
                      "text":"books ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":112,
                      "text":"by ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":113,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":114,
                      "text":"featured ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":115,
                      "text":"doctors ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":116,
                      "text":"like ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "including",
                         ":",
                         "except"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":117,
                      "text":"\"",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":118,
                      "text":"The ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":119,
                      "text":"China ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":120,
                      "text":"Study",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":121,
                      "text":"\" ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":122,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":123,
                      "text":"\"",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":124,
                      "text":"How ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":125,
                      "text":"Not ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":126,
                      "text":"To ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":127,
                      "text":"Die",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "lie",
                         "forget",
                         "eat"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":128,
                      "text":"\"",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":129,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"We read all the books by the featured doctors like \"The China Study\" and \"How Not To Die\".",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":130,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":131,
                      "text":"became ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":132,
                      "text":"entranced ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":133,
                      "text":"by ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":134,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":135,
                      "text":"world ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":136,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":137,
                      "text":"nutritional ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":138,
                      "text":"science ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":139,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":140,
                      "text":"how ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":141,
                      "text":"certain ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":142,
                      "text":"foods ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":143,
                      "text":"could ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":144,
                      "text":"help ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "potentially",
                         "either",
                         "possibly"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":145,
                      "text":"prevent ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":146,
                      "text":"cancer ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":147,
                      "text":"or ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":148,
                      "text":"boost ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":149,
                      "text":"metabolism",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":150,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"I became entranced by the world of nutritional science and how certain foods could help prevent cancer or boost metabolism.",
                   "errorTypes":[
                      "syntax"
                   ],
                   "errorId":[
                      "54_0"
                   ],
                   "cardId":null
                }
             }
          ]
       },
       {
          "para":{
             "text":"Each new food I discovered gave me an education on the role diet plays on health. I learned that, by eating sweet potatoes and brown rice, you could cure acne and heart disease. I discovered eating leafy greens with citrus fruits could boost iron absorption rates. I loved pairing my foods to create the perfect macronutrient balance. Did you know beans and rice make a complete protein?",
             "errorTypes":[
                
             ],
             "errorId":[
                
             ],
             "cardId":null
          },
          "sentencesTokens":[
             {
                "tokens":[
                   {
                      "ind":151,
                      "text":"Each ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":152,
                      "text":"new ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":153,
                      "text":"food ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":154,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":155,
                      "text":"discovered ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":156,
                      "text":"gave ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "provided",
                         "offered",
                         "afforded"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":157,
                      "text":"me ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":158,
                      "text":"an ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":159,
                      "text":"education ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":160,
                      "text":"on ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":161,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":162,
                      "text":"role ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":163,
                      "text":"diet ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":164,
                      "text":"plays ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "played",
                         "had",
                         "has"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":165,
                      "text":"on ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":166,
                      "text":"health",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":167,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"Each new food I discovered gave me an education on the role diet plays on health.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":168,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":169,
                      "text":"learned ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "believe",
                         "hoped",
                         "thought"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":170,
                      "text":"that",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":171,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":172,
                      "text":"by ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":173,
                      "text":"eating ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":174,
                      "text":"sweet ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":175,
                      "text":"potatoes ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":176,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":177,
                      "text":"brown ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":178,
                      "text":"rice",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":179,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":180,
                      "text":"you ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "9_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":181,
                      "text":"could ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":182,
                      "text":"cure ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":183,
                      "text":"acne ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":184,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":185,
                      "text":"heart ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":186,
                      "text":"disease",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":187,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"I learned that, by eating sweet potatoes and brown rice, you could cure acne and heart disease.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":188,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":189,
                      "text":"discovered ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":190,
                      "text":"eating ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":191,
                      "text":"leafy ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":192,
                      "text":"greens ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":193,
                      "text":"with ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":194,
                      "text":"citrus ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":195,
                      "text":"fruits ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":196,
                      "text":"could ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":197,
                      "text":"boost ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":198,
                      "text":"iron ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":199,
                      "text":"absorption ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":200,
                      "text":"rates",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":201,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"I discovered eating leafy greens with citrus fruits could boost iron absorption rates.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":202,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":203,
                      "text":"loved ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "recommend",
                         "prefer",
                         "suggest"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":204,
                      "text":"pairing ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":205,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":206,
                      "text":"foods ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":207,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":208,
                      "text":"create ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":209,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":210,
                      "text":"perfect ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":211,
                      "text":"macronutrient ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":212,
                      "text":"balance",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":213,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"I loved pairing my foods to create the perfect macronutrient balance.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":214,
                      "text":"Did ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":215,
                      "text":"you ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "9_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":216,
                      "text":"know ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":217,
                      "text":"beans ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":218,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":219,
                      "text":"rice ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":220,
                      "text":"make ",
                      "errorTypes":[
                         "grammar",
                         "grammar"
                      ],
                      "errorId":[
                         "3",
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         "as",
                         "are",
                         "were"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":221,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":222,
                      "text":"complete ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":223,
                      "text":"protein",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":224,
                      "text":"? ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"Did you know beans and rice make a complete protein?",
                   "errorTypes":[
                      "syntax"
                   ],
                   "errorId":[
                      "25_0"
                   ],
                   "cardId":null
                }
             }
          ]
       },
       {
          "para":{
             "text":"Food has also turned me into a sustainability nut. Living plant-based also saves the planet from the impact of animal agriculture. For the same amount of land space, a farmer can produce 200 kilograms of soybeans versus 16 kilograms of beef. I do my part to have as small of an ecological footprint as I can. I stopped using plastic snack bags and instead turned to reusable beeswax wraps. My favorite reusable appliance is my foldable straw. If I am going to nourish my body, shouldn't I also want to nourish the earth?",
             "errorTypes":[
                
             ],
             "errorId":[
                
             ],
             "cardId":null
          },
          "sentencesTokens":[
             {
                "tokens":[
                   {
                      "ind":225,
                      "text":"Food ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":226,
                      "text":"has ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":227,
                      "text":"also ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":228,
                      "text":"turned ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "transformed",
                         "converted",
                         "made"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":229,
                      "text":"me ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":230,
                      "text":"into ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":231,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":232,
                      "text":"sustainability ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":233,
                      "text":"nut",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":234,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"Food has also turned me into a sustainability nut.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":235,
                      "text":"Living ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "this",
                         "sustainable",
                         "organic"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":236,
                      "text":"plant",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":237,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":238,
                      "text":"based ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":239,
                      "text":"also ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":240,
                      "text":"saves ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":241,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":242,
                      "text":"planet ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":243,
                      "text":"from ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":244,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":245,
                      "text":"impact ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":246,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":247,
                      "text":"animal ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":248,
                      "text":"agriculture",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":249,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"Living plant-based also saves the planet from the impact of animal agriculture.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":250,
                      "text":"For ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":251,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":252,
                      "text":"same ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":253,
                      "text":"amount ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":254,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":255,
                      "text":"land ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":256,
                      "text":"space",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":257,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":258,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":259,
                      "text":"farmer ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":260,
                      "text":"can ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "must",
                         "should",
                         "will"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":261,
                      "text":"produce ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":262,
                      "text":"200 ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "28_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":263,
                      "text":"kilograms ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "28_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":264,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":265,
                      "text":"soybeans ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":266,
                      "text":"versus ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":267,
                      "text":"16 ",
                      "errorTypes":[
                         "grammar",
                         "grammar"
                      ],
                      "errorId":[
                         "18_0",
                         "28_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":268,
                      "text":"kilograms ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "28_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":269,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":270,
                      "text":"beef",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":271,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"For the same amount of land space, a farmer can produce 200 kilograms of soybeans versus 16 kilograms of beef.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":272,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":273,
                      "text":"do ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":274,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":275,
                      "text":"part ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":276,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":277,
                      "text":"have ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":278,
                      "text":"as ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":279,
                      "text":"small ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":280,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":281,
                      "text":"an ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":282,
                      "text":"ecological ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":283,
                      "text":"footprint ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":284,
                      "text":"as ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":285,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":286,
                      "text":"can",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "wish",
                         "want",
                         "do"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":287,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"I do my part to have as small of an ecological footprint as I can.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":288,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":289,
                      "text":"stopped ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":290,
                      "text":"using ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "selling",
                         "eating",
                         "grabbing"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":291,
                      "text":"plastic ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":292,
                      "text":"snack ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":293,
                      "text":"bags ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":294,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":295,
                      "text":"instead ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":296,
                      "text":"turned ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "switched",
                         "listened",
                         "tried"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":297,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":298,
                      "text":"reusable ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":299,
                      "text":"beeswax ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":300,
                      "text":"wraps",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":301,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"I stopped using plastic snack bags and instead turned to reusable beeswax wraps.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":302,
                      "text":"My ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":303,
                      "text":"favorite ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":304,
                      "text":"reusable ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":305,
                      "text":"appliance ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":306,
                      "text":"is ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":307,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":308,
                      "text":"foldable ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":309,
                      "text":"straw",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":310,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"My favorite reusable appliance is my foldable straw.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":311,
                      "text":"If ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "30"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":312,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":313,
                      "text":"am ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":314,
                      "text":"going ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "able",
                         "willing",
                         "ready"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":315,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":316,
                      "text":"nourish ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":317,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":318,
                      "text":"body",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":319,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":320,
                      "text":"should",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "why",
                         "ai",
                         "y"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":321,
                      "text":"n't ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":322,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":323,
                      "text":"also ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":324,
                      "text":"want ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "try",
                         "go",
                         "promise"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":325,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":326,
                      "text":"nourish ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":327,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":328,
                      "text":"earth",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":329,
                      "text":"? ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"If I am going to nourish my body, shouldn't I also want to nourish the earth?",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             }
          ]
       },
       {
          "para":{
             "text":"My journey toward healthy living led me to becoming co-leader of the Northern Nevada PlantPure Pod, \"Biggest Little Plant Pod\", a group dedicated to spreading the message about the whole food plant-based lifestyle. We are currently working on a restaurant campaign to encourage local eateries to create a plant-based, oil-free menu option and become PlantPure certified. After discovering how many restaurants use oil in their cooking, I decided I needed to open a plant-based oil free cafe to make up for this gap. My dream is to open up my very own affordable oatmeal cafe based on my Instagram page, morning_mOATivations. And I know that oatmeal isn't the sexiest superfood out there, so here's my sales pitch: I'm going to make oatmeal the Beyonce of the breakfast world- sweet, sassy, and power packed. This allows me to educate people about nutritional science through the stomach.",
             "errorTypes":[
                "syntax"
             ],
             "errorId":[
                "17_0"
             ],
             "cardId":null
          },
          "sentencesTokens":[
             {
                "tokens":[
                   {
                      "ind":330,
                      "text":"My ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":331,
                      "text":"journey ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":332,
                      "text":"toward ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":333,
                      "text":"healthy ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":334,
                      "text":"living ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":335,
                      "text":"led ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "leads",
                         "brought",
                         "lead"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":336,
                      "text":"me ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":337,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":338,
                      "text":"becoming ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":339,
                      "text":"co",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":340,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":341,
                      "text":"leader ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":342,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":343,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":344,
                      "text":"Northern ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":345,
                      "text":"Nevada ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":346,
                      "text":"PlantPure ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":347,
                      "text":"Pod",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":348,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":349,
                      "text":"\"",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":350,
                      "text":"Biggest ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":351,
                      "text":"Little ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":352,
                      "text":"Plant ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":353,
                      "text":"Pod",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":354,
                      "text":"\"",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":355,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":356,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":357,
                      "text":"group ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":358,
                      "text":"dedicated ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":359,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":360,
                      "text":"spreading ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":361,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":362,
                      "text":"message ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":363,
                      "text":"about ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":364,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":365,
                      "text":"whole ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":366,
                      "text":"food ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":367,
                      "text":"plant",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":368,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":369,
                      "text":"based ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":370,
                      "text":"lifestyle",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":371,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"My journey toward healthy living led me to becoming co-leader of the Northern Nevada PlantPure Pod, \"Biggest Little Plant Pod\", a group dedicated to spreading the message about the whole food plant-based lifestyle.",
                   "errorTypes":[
                      "syntax"
                   ],
                   "errorId":[
                      "32_0"
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":372,
                      "text":"We ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":373,
                      "text":"are ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":374,
                      "text":"currently ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":375,
                      "text":"working ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":376,
                      "text":"on ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":377,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":378,
                      "text":"restaurant ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":379,
                      "text":"campaign ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":380,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":381,
                      "text":"encourage ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":382,
                      "text":"local ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":383,
                      "text":"eateries ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":384,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":385,
                      "text":"create ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":386,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":387,
                      "text":"plant",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":388,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":389,
                      "text":"based",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":390,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":391,
                      "text":"oil",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":392,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":393,
                      "text":"free ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":394,
                      "text":"menu ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":395,
                      "text":"option ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":396,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":397,
                      "text":"become ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":398,
                      "text":"PlantPure ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":399,
                      "text":"certified",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":400,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"We are currently working on a restaurant campaign to encourage local eateries to create a plant-based, oil-free menu option and become PlantPure certified.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":401,
                      "text":"After ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":402,
                      "text":"discovering ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":403,
                      "text":"how ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":404,
                      "text":"many ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":405,
                      "text":"restaurants ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":406,
                      "text":"use ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "used",
                         "utilize",
                         "utilized"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":407,
                      "text":"oil ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":408,
                      "text":"in ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":409,
                      "text":"their ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":410,
                      "text":"cooking",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":411,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":412,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":413,
                      "text":"decided ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":414,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":415,
                      "text":"needed ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "decided",
                         "wanted",
                         "chose"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":416,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":417,
                      "text":"open ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "create",
                         "establish",
                         "build"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":418,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":419,
                      "text":"plant",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":420,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":421,
                      "text":"based ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":422,
                      "text":"oil ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":423,
                      "text":"free ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":424,
                      "text":"cafe ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":425,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":426,
                      "text":"make ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":427,
                      "text":"up ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":428,
                      "text":"for ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":429,
                      "text":"this ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":430,
                      "text":"gap",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":431,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"After discovering how many restaurants use oil in their cooking, I decided I needed to open a plant-based oil free cafe to make up for this gap.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":432,
                      "text":"My ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":433,
                      "text":"dream ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":434,
                      "text":"is ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":435,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":436,
                      "text":"open ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "set",
                         "start",
                         "setting"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":437,
                      "text":"up ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":438,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":439,
                      "text":"very ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "2"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":440,
                      "text":"own ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":441,
                      "text":"affordable ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":442,
                      "text":"oatmeal ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":443,
                      "text":"cafe ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":444,
                      "text":"based ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":445,
                      "text":"on ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":446,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":447,
                      "text":"Instagram ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":448,
                      "text":"page",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":449,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":450,
                      "text":"morning_mOATivations",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":451,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"My dream is to open up my very own affordable oatmeal cafe based on my Instagram page, morning_mOATivations.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":452,
                      "text":"And ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":453,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":454,
                      "text":"know ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":455,
                      "text":"that ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":456,
                      "text":"oatmeal ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":457,
                      "text":"is",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":458,
                      "text":"n't ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":459,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":460,
                      "text":"sexiest ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":461,
                      "text":"superfood ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":462,
                      "text":"out ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":463,
                      "text":"there",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":464,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":465,
                      "text":"so ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":466,
                      "text":"here",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":467,
                      "text":"'s ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":468,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":469,
                      "text":"sales ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":470,
                      "text":"pitch",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":471,
                      "text":": ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":472,
                      "text":"I",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":473,
                      "text":"'m ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":474,
                      "text":"going ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "trying",
                         "ready",
                         "determined"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":475,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":476,
                      "text":"make ",
                      "errorTypes":[
                         "grammar",
                         "grammar"
                      ],
                      "errorId":[
                         "3",
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         "give",
                         "buy",
                         "sell"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":477,
                      "text":"oatmeal ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":478,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":479,
                      "text":"Beyonce ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":480,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":481,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":482,
                      "text":"breakfast ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":483,
                      "text":"world- ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":484,
                      "text":"sweet",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":485,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":486,
                      "text":"sassy",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":487,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":488,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":489,
                      "text":"power ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":490,
                      "text":"packed",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":491,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"And I know that oatmeal isn't the sexiest superfood out there, so here's my sales pitch: I'm going to make oatmeal the Beyonce of the breakfast world- sweet, sassy, and power packed.",
                   "errorTypes":[
                      "syntax",
                      "syntax"
                   ],
                   "errorId":[
                      "32_0",
                      "42_0"
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":492,
                      "text":"This ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":493,
                      "text":"allows ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":494,
                      "text":"me ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":495,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":496,
                      "text":"educate ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":497,
                      "text":"people ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":498,
                      "text":"about ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":499,
                      "text":"nutritional ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":500,
                      "text":"science ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":501,
                      "text":"through ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":502,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":503,
                      "text":"stomach",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":504,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"This allows me to educate people about nutritional science through the stomach.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             }
          ]
       },
       {
          "para":{
             "text":"Finally, I am a strong proponent of hands-on experience for learning what good food looks and tastes like, so cooking is one of my favorite ways to teach the benefits of a plant-based lifestyle. Using my taste buds as my textbook to learn which flavors work together and which ones don't helps me educate, as I've found that information tends to stick in a person's mind once they've experienced healthy, delicious foods with their own senses. Our society has taught us that delicious food has to make us feel guilty, when that is simply not the case. The best feeling in the world is falling in love with a dish and then learning all the health benefits that it provides the body.",
             "errorTypes":[
                "structure"
             ],
             "errorId":[
                "45_0"
             ],
             "cardId":null
          },
          "sentencesTokens":[
             {
                "tokens":[
                   {
                      "ind":505,
                      "text":"Finally",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":506,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":507,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":508,
                      "text":"am ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":509,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":510,
                      "text":"strong ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":511,
                      "text":"proponent ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":512,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":513,
                      "text":"hands",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":514,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":515,
                      "text":"on ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":516,
                      "text":"experience ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":517,
                      "text":"for ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":518,
                      "text":"learning ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "knowing",
                         "deciding",
                         "determining"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":519,
                      "text":"what ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":520,
                      "text":"good ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "2"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":521,
                      "text":"food ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":522,
                      "text":"looks ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "smells",
                         "tastes",
                         "feels"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":523,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":524,
                      "text":"tastes ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":525,
                      "text":"like",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "differently",
                         "best",
                         "better"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":526,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":527,
                      "text":"so ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":528,
                      "text":"cooking ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":529,
                      "text":"is ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":530,
                      "text":"one ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":531,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":532,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":533,
                      "text":"favorite ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":534,
                      "text":"ways ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":535,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":536,
                      "text":"teach ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":537,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":538,
                      "text":"benefits ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":539,
                      "text":"of ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":540,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":541,
                      "text":"plant",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":542,
                      "text":"-",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":543,
                      "text":"based ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":544,
                      "text":"lifestyle",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":545,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"Finally, I am a strong proponent of hands-on experience for learning what good food looks and tastes like, so cooking is one of my favorite ways to teach the benefits of a plant-based lifestyle.",
                   "errorTypes":[
                      "syntax"
                   ],
                   "errorId":[
                      "32_0"
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":546,
                      "text":"Using ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "use",
                         "uses",
                         "utilize"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":547,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":548,
                      "text":"taste ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":549,
                      "text":"buds ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":550,
                      "text":"as ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":551,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":552,
                      "text":"textbook ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":553,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":554,
                      "text":"learn ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "determine",
                         "decide",
                         "discover"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":555,
                      "text":"which ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "30"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":556,
                      "text":"flavors ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":557,
                      "text":"work ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":558,
                      "text":"together ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":559,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":560,
                      "text":"which ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "30"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":561,
                      "text":"ones ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":562,
                      "text":"do",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":563,
                      "text":"n't ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":564,
                      "text":"helps ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "let",
                         "help",
                         "make"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":565,
                      "text":"me ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":566,
                      "text":"educate",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":567,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":568,
                      "text":"as ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":569,
                      "text":"I",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":570,
                      "text":"'ve ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":571,
                      "text":"found ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "learned",
                         "noticed",
                         "discovered"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":572,
                      "text":"that ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":573,
                      "text":"information ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":574,
                      "text":"tends ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":575,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":576,
                      "text":"stick ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":577,
                      "text":"in ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":578,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":579,
                      "text":"person",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":580,
                      "text":"'s ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":581,
                      "text":"mind ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":582,
                      "text":"once ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":583,
                      "text":"they",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":584,
                      "text":"'ve ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":585,
                      "text":"experienced ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":586,
                      "text":"healthy",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":587,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":588,
                      "text":"delicious ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":589,
                      "text":"foods ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":590,
                      "text":"with ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":591,
                      "text":"their ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":592,
                      "text":"own ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":593,
                      "text":"senses",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":594,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"Using my taste buds as my textbook to learn which flavors work together and which ones don't helps me educate, as I've found that information tends to stick in a person's mind once they've experienced healthy, delicious foods with their own senses.",
                   "errorTypes":[
                      "syntax"
                   ],
                   "errorId":[
                      "32_0"
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":595,
                      "text":"Our ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":596,
                      "text":"society ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":597,
                      "text":"has ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":598,
                      "text":"taught ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":599,
                      "text":"us ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":600,
                      "text":"that ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":601,
                      "text":"delicious ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":602,
                      "text":"food ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":603,
                      "text":"has ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":604,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":605,
                      "text":"make ",
                      "errorTypes":[
                         "grammar",
                         "grammar"
                      ],
                      "errorId":[
                         "3",
                         "24_0"
                      ],
                      "cardId":null,
                      "replacements":[
                         "help",
                         "made",
                         "let"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":606,
                      "text":"us ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":607,
                      "text":"feel ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "taste",
                         "appear",
                         "less"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":608,
                      "text":"guilty",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":609,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":610,
                      "text":"when ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":611,
                      "text":"that ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":612,
                      "text":"is ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":613,
                      "text":"simply ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":614,
                      "text":"not ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":615,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":616,
                      "text":"case",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":617,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"Our society has taught us that delicious food has to make us feel guilty, when that is simply not the case.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":618,
                      "text":"The ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":619,
                      "text":"best ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":620,
                      "text":"feeling ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":621,
                      "text":"in ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":622,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":623,
                      "text":"world ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":624,
                      "text":"is ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":625,
                      "text":"falling ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "getting",
                         "madly",
                         "being"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":626,
                      "text":"in ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":627,
                      "text":"love ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "contact",
                         "line",
                         "touch"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":628,
                      "text":"with ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":629,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":630,
                      "text":"dish ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":631,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":632,
                      "text":"then ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":633,
                      "text":"learning ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "enjoying",
                         "receiving",
                         "getting"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":634,
                      "text":"all ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":635,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":636,
                      "text":"health ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":637,
                      "text":"benefits ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":638,
                      "text":"that ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":639,
                      "text":"it ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":640,
                      "text":"provides ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":641,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":642,
                      "text":"body",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":643,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"The best feeling in the world is falling in love with a dish and then learning all the health benefits that it provides the body.",
                   "errorTypes":[
                      "flow"
                   ],
                   "errorId":[
                      "46_0"
                   ],
                   "cardId":null
                }
             }
          ]
       },
       {
          "para":{
             "text":"While my classmates complain about being tired, I have more energy because my body is finally getting the right macros, vitamins, and minerals it needs. This has allowed me to push myself harder physically, excelling in running and earning my high school Cross Country team's Most Improved award. I'm still a picky eater. But the foods I am particular about have changed. Rather than a carboholic, I choose to call myself a vegeholic.",
             "errorTypes":[
                
             ],
             "errorId":[
                
             ],
             "cardId":null
          },
          "sentencesTokens":[
             {
                "tokens":[
                   {
                      "ind":644,
                      "text":"While ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "30"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":645,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":646,
                      "text":"classmates ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":647,
                      "text":"complain ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":648,
                      "text":"about ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":649,
                      "text":"being ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":650,
                      "text":"tired",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":651,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":652,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":653,
                      "text":"have ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":654,
                      "text":"more ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":655,
                      "text":"energy ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":656,
                      "text":"because ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "30"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":657,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":658,
                      "text":"body ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":659,
                      "text":"is ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":660,
                      "text":"finally ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":661,
                      "text":"getting ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "finding",
                         "given",
                         "gathering"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":662,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":663,
                      "text":"right ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":664,
                      "text":"macros",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":665,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":666,
                      "text":"vitamins",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":667,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":668,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":669,
                      "text":"minerals ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":670,
                      "text":"it ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":671,
                      "text":"needs",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "requires",
                         "contains",
                         "takes"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":672,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"While my classmates complain about being tired, I have more energy because my body is finally getting the right macros, vitamins, and minerals it needs.",
                   "errorTypes":[
                      "flow"
                   ],
                   "errorId":[
                      "46_0"
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":673,
                      "text":"This ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":674,
                      "text":"has ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":675,
                      "text":"allowed ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":676,
                      "text":"me ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":677,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":678,
                      "text":"push ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":679,
                      "text":"myself ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":680,
                      "text":"harder ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":681,
                      "text":"physically",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":682,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":683,
                      "text":"excelling ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":684,
                      "text":"in ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":685,
                      "text":"running ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":686,
                      "text":"and ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":687,
                      "text":"earning ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":688,
                      "text":"my ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":689,
                      "text":"high ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":690,
                      "text":"school ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":691,
                      "text":"Cross ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":692,
                      "text":"Country ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":693,
                      "text":"team",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":694,
                      "text":"'s ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":695,
                      "text":"Most ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":696,
                      "text":"Improved ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":697,
                      "text":"award",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":698,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"This has allowed me to push myself harder physically, excelling in running and earning my high school Cross Country team's Most Improved award.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":699,
                      "text":"I",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":700,
                      "text":"'m ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":701,
                      "text":"still ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":702,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":703,
                      "text":"picky ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":704,
                      "text":"eater",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":705,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"I'm still a picky eater.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":706,
                      "text":"But ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":707,
                      "text":"the ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":708,
                      "text":"foods ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":709,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":710,
                      "text":"am ",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "34"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":711,
                      "text":"particular ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":712,
                      "text":"about ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":713,
                      "text":"have ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":714,
                      "text":"changed",
                      "errorTypes":[
                         "grammar"
                      ],
                      "errorId":[
                         "3"
                      ],
                      "cardId":null,
                      "replacements":[
                         "disappeared",
                         "vanished",
                         "none"
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":715,
                      "text":". ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"But the foods I am particular about have changed.",
                   "errorTypes":[
                      "syntax"
                   ],
                   "errorId":[
                      "42_0"
                   ],
                   "cardId":null
                }
             },
             {
                "tokens":[
                   {
                      "ind":716,
                      "text":"Rather ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "30"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":717,
                      "text":"than ",
                      "errorTypes":[
                         "syntax"
                      ],
                      "errorId":[
                         "30"
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":718,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":719,
                      "text":"carboholic",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":720,
                      "text":", ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":721,
                      "text":"I ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":722,
                      "text":"choose ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":723,
                      "text":"to ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":724,
                      "text":"call ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":725,
                      "text":"myself ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":726,
                      "text":"a ",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":727,
                      "text":"vegeholic",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   },
                   {
                      "ind":728,
                      "text":".",
                      "errorTypes":[
                         
                      ],
                      "errorId":[
                         
                      ],
                      "cardId":null,
                      "replacements":[
                         
                      ],
                      "plagiarism":null
                   }
                ],
                "sent":{
                   "text":"Rather than a carboholic, I choose to call myself a vegeholic.",
                   "errorTypes":[
                      
                   ],
                   "errorId":[
                      
                   ],
                   "cardId":null
                }
             }
          ]
       }
    ],
    "errors":[
       {
          "title":"Check the paragraph length",
          "type":"syntax",
          "id":"17_0",
          "fullErrorExplan":"This paragraph appears to be disproportionally long. Try to keep your essay balanced and de-word your writing ",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Long sentences or paragraphs stand out because, well, they're longer relative to the rest of the essay. Keep the story balanced"
                ]
             },
             {
                "title":"Good example",
                "description":"I built a human-sized clay sculpture of a young woman who was smiling peacefully"
             },
             {
                "title":"Bad example",
                "description":"I built a sculpture that was made out of clay and metal wires and was of a young woman smiling peacefully at something or someone"
             }
          ],
          "cardId":"card_6",
          "score":3.0,
          "singleShow":false,
          "bucket":"A22"
       },
       {
          "title":"Replace \"to be\" with action verbs",
          "type":"grammar",
          "id":"34",
          "fullErrorExplan":"Reduce the number of \"to be\" verbs. Best practice is to replace them with action verbs to make the writing more vivid",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "The final output needs to be sharp, lean, right to the point. Think a space ship vs. an ocean liner. Makes your essay a much easier read"
                ]
             },
             {
                "title":"Good example",
                "description":"My older sister showed me how to play the guitar. I instantly got hooked and have been watching how-to videos day and night"
             },
             {
                "title":"Bad example",
                "description":"I've been trying to master the guitar. I was shown how to play by my older sister and have been watching how-to videos"
             }
          ],
          "cardId":"card_23",
          "score":0.5,
          "singleShow":false,
          "bucket":"B37"
       },
       {
          "title":"Consider using synonyms",
          "type":"grammar",
          "id":"3",
          "fullErrorExplan":"Vary your word choice. Use thesaurus to find  synonyms but never use complex words where you may be unsure about the meaning",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Repetition doesn't improve the essay. Limited vocabulary makes your writing a bit stiff and constraints your storytelling ability"
                ]
             },
             {
                "title":"Good example",
                "description":"My dog ran after a tennis ball knocking over futniture. But the pet's really my friend, despite, or thanks to, all the canine silliness "
             },
             {
                "title":"Bad example",
                "description":"My dog ran after a tennis ball. The dog knocked over a tea table. He's such a silly dog but I love it that he brings so much energy"
             }
          ],
          "cardId":"card_20",
          "score":1.0,
          "singleShow":false,
          "bucket":"A33"
       },
       {
          "title":"Replace with synonyms",
          "type":"grammar",
          "id":"24_0",
          "fullErrorExplan":"Replace repeating words with synonyms. Find alternatives that sound better, read easier, or better convey the intended meaning",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Use varied vocabulary to be more precise and tailored in your descriptions and messaging. Limited vocab implies a sloppy essay"
                ]
             },
             {
                "title":"Good example",
                "description":"I captured all key insights in my notes and deduced key differenes and similarities between the two schools of thought"
             },
             {
                "title":"Bad example",
                "description":"I captured all key insights in my notes; I also captured the differences and similarities between the two schools of thought"
             }
          ],
          "cardId":"card_21",
          "score":1.0,
          "singleShow":false,
          "bucket":"A35"
       },
       {
          "title":"Keep your writing positive",
          "type":"flow",
          "id":"7_0",
          "fullErrorExplan":"Your writing seems a bit negative. Avoid sad emotions, however powerful. Put a positive spin on things regardless of the situation",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "As tempting as it may be, focus on talking about your experiences and unique learnings vs. making the reader overly emotional"
                ]
             },
             {
                "title":"Good example",
                "description":"My grandmother's passing made me think about the thousands of people she impacted as a teacher. This is the legacy I\u2019d like to leave"
             },
             {
                "title":"Bad example",
                "description":"The death of my grandmother was too tragic. I just spoke to her on the phone over the weekend and now she was gone"
             }
          ],
          "cardId":"card_5",
          "score":1.0,
          "singleShow":false,
          "bucket":"E20"
       },
       {
          "title":"Increase specificity",
          "type":"flow",
          "id":"50_0",
          "fullErrorExplan":"You seem to be moving in the wrong direction. Your writing is becoming progressively more high level",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "In sorytelling, the author takes the reader on a journey and introduces more detail over time. You seem to introduce less detail though"
                ]
             },
             {
                "title":"Good example",
                "description":"Playing in front of others was nerve wracking. My hands shook and I froze in a panic. I saw the music sheet but couldn\u2019t read the notes "
             },
             {
                "title":"Bad example",
                "description":"I saw the music sheet but couldn't read the notes. My hands shook and I froze. Playing in front of others was nerve wracking"
             }
          ],
          "cardId":"card_3",
          "score":3.0,
          "singleShow":false,
          "bucket":"C14"
       },
       {
          "title":"Avoid being categorical",
          "type":"flow",
          "id":"22_0",
          "fullErrorExplan":"Don't make categorical statements. Best illustrate extreme situations through memorable imagery vs. assertions",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Be careful about making categorical statements. Almost every assertion has exceptions that you ought to acknowledge"
                ]
             },
             {
                "title":"Good example",
                "description":"Rachael was disappointed about losing the race. She couldn't talk about it without choking up at least through sophomore year"
             },
             {
                "title":"Bad example",
                "description":"I've never seen anyone as upset as Rachael about losing a race. She was by far most disappointed with not hitting her PR"
             }
          ],
          "cardId":"card_4",
          "score":0.5,
          "singleShow":false,
          "bucket":"D18"
       },
       {
          "title":"Use punchier word choice",
          "type":"syntax",
          "id":"2",
          "fullErrorExplan":"If you are being vague, well, don't be! Inconclusive words clutter your essay. As much as possible, quantify and add specifics",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Vague words are distracting because you lose your grip of the story and allow the reader to make assumptions & guess"
                ]
             },
             {
                "title":"Good example",
                "description":"For my end-of-year Physics project, I wanted to build a miniature electronic vehicle. I spent days and nights at the library doing research "
             },
             {
                "title":"Bad example",
                "description":"I wanted to do something special for the Physics project. I spent days and nights at the library doing research on e-vehicles"
             }
          ],
          "cardId":"card_14",
          "score":2.0,
          "singleShow":false,
          "bucket":"B26"
       },
       {
          "title":"Replace the passive voice",
          "type":"syntax",
          "id":"54_0",
          "fullErrorExplan":"Replace the passive voice with the active voice to make the flow better and the comprehension easier",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Active voice better conveys action, pun intended. It also improves the flow of the essay, making it easier to read & comprehend"
                ]
             },
             {
                "title":"Good example",
                "description":"The flight attendant asked to see my ticket but I realized that I seemed to have lost it"
             },
             {
                "title":"Bad example",
                "description":"I was asked by the flight attendant to show my ticket but was quick to realize that I appeared to have lost it somewhere"
             }
          ],
          "cardId":"card_17",
          "score":1.0,
          "singleShow":false,
          "bucket":"B31"
       },
       {
          "title":"Try not to use \"you\"",
          "type":"syntax",
          "id":"9_0",
          "fullErrorExplan":"Don't address the reader as \"you\". The essay isn't a casual dialogue with a friend and you don't know how the reader may react",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "The reader may or may not emphasize with your story. Don't project it on them! Instead, underscore your own takeaways & feelings"
                ]
             },
             {
                "title":"Good example",
                "description":"At first I didn't understand the fun of playing chess. Then I understood the game with a myriad of different tactics & got hooked"
             },
             {
                "title":"Bad example",
                "description":"I couldn't understand your obsession with chess. You'd play every day and night. But then I understood the game & got hooked"
             }
          ],
          "cardId":"card_18",
          "score":1.0,
          "singleShow":false,
          "bucket":"C32"
       },
       {
          "title":"Try not to ask questions",
          "type":"syntax",
          "id":"25_0",
          "fullErrorExplan":"Hold off on asking rhetorical questions. The essay is about you, the author. The reader may or may not want to join the dialogue",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Rhetorical questions take up space but don't convey any new insights about you. The essay should be your story vs. a dialogue"
                ]
             },
             {
                "title":"Good example",
                "description":"I recall one scary experience. I got a bad cold once but continued training for the marathon, pushing until my body broke "
             },
             {
                "title":"Bad example",
                "description":"Have you ever been scared for your life? Have you ever wondered what would have happen had you just stayed in bed?"
             }
          ],
          "cardId":"card_19",
          "score":1.0,
          "singleShow":false,
          "bucket":"C33"
       },
       {
          "title":"Use the US metric system",
          "type":"grammar",
          "id":"28_0",
          "fullErrorExplan":"Replace with US units of measure. Don't make the reader pull out a calculator or turn to Google for help with units conversion",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "You want to reduce the friction for the reader as much as possible. Any confusing inputs may not convey your point of view fully"
                ]
             },
             {
                "title":"Good example",
                "description":"I did a deadline with 200 pounds and 10 squats with 200 pounds. I was happy with the result, especially at 5'8'' and 154 pounds"
             },
             {
                "title":"Bad example",
                "description":"I did a deadlift with 100 kgs and 10 squats with 100 kgs. I was happy with the result, especially at 178 cms and 70 kgs"
             }
          ],
          "cardId":"card_25",
          "score":0.5,
          "singleShow":false,
          "bucket":"C40"
       },
       {
          "title":"Spell out small numbers",
          "type":"grammar",
          "id":"18_0",
          "fullErrorExplan":"Use words vs. numbers with small numbers less than 20. It's a minor improvement but keeps the writing clean and consistent ",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Ideally, the reader races through the essay in minutes. Small things, e.g., spelling out numbers, could contribute to smoother flow"
                ]
             },
             {
                "title":"Good example",
                "description":"I scored eighteen points and eight assists in my last home game. Today I'm aiming for a double-double with at least ten assists"
             },
             {
                "title":"Bad example",
                "description":"I scored 18 points and 8 assists during last week's Sunday game. Today I was hoping to do a double-double, aiming for 10 assists"
             }
          ],
          "cardId":"card_24",
          "score":0.5,
          "singleShow":false,
          "bucket":"C39"
       },
       {
          "title":"Simplify by removing clauses",
          "type":"syntax",
          "id":"30",
          "fullErrorExplan":"Replace clauses with complete sentences or rephrase for a more conversational flow. Avoid awkwardly worded disjointed sentences",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Clauses is an easy way to incrementally add new details. A downside is that clauses can make the writing confusing or disrupt the flow "
                ]
             },
             {
                "title":"Good example",
                "description":"My grandparents gave me a drum set for Christmas when I turned nine. I played daily ever since, and my technique has evolved"
             },
             {
                "title":"Bad example",
                "description":"When I just started learning how to play the drums, I didn't have fun. As I became more experienced, my technique improved "
             }
          ],
          "cardId":"card_13",
          "score":2.0,
          "singleShow":false,
          "bucket":"A25"
       },
       {
          "title":"Normalize sentence length",
          "type":"syntax",
          "id":"32_0",
          "fullErrorExplan":"Deword this sentence as it's significantly longer than other sentences. Varying sentence length is good but don't go to the extreme",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Longer sentences take longer to understand and unpack. Unless you're a descendant of Leo Tolstoy, don't write in long sentence"
                ]
             },
             {
                "title":"Good example",
                "description":"I went for a run but stopped every 10 minutes to catch a breath, feeling the impact of COVID"
             },
             {
                "title":"Bad example",
                "description":"I went for a run but couldn't run for more than 10 minutes without stopping to catch my breath, really feeling the impact of COVID"
             }
          ],
          "cardId":"card_9",
          "score":3.0,
          "singleShow":false,
          "bucket":"A23"
       },
       {
          "title":"Think of a better sentence starter",
          "type":"syntax",
          "id":"42_0",
          "fullErrorExplan":"\"And\" or \"But\" are just not great sentence starters. Consider rewording the sentence slightly for better flow and readibility",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "Technically there's nothing wrong with \"And\" or \"But\" in the beginning of a sentence. Stylistically best to start with nouns/pronouns"
                ]
             },
             {
                "title":"Good example",
                "description":"I went hiking up a steep mountaint but didn't anticipate that the trail would be swampy. Apparently, heavy rains have flooded the area"
             },
             {
                "title":"Bad example",
                "description":"I went hiking up a steep mountain. But what I didn't anticipate is that the trail would be swampy after several days of rain."
             }
          ],
          "cardId":"card_15",
          "score":0.5,
          "singleShow":false,
          "bucket":"B30"
       },
       {
          "title":"You're missing an insight",
          "type":"structure",
          "id":"45_0",
          "fullErrorExplan":"Ensure that every paragraph has a take-away, i.e., an incremental clue helping the reader to get to know you better through the essay",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "The essay is really a proxy for who you are as a person and how good you'd fit on campus. Make sure you weave in your reflections"
                ]
             },
             {
                "title":"Good example",
                "description":"No one hugged the ball. We passed it to each other effortlessly. This experience taught me the importance of being a team player"
             },
             {
                "title":"Bad example",
                "description":"No one hogged the ball. We passed it to each other effortlessly, creating numerous opportunities for everyone to score"
             }
          ],
          "cardId":"card_0",
          "score":5.0,
          "singleShow":true,
          "bucket":"B3"
       },
       {
          "title":"Use more of \"show\" vs. \"tell\"",
          "type":"flow",
          "id":"46_0",
          "fullErrorExplan":"Tell a memorable story by using more of \"show\" vs. \"tell\". Your essay needs to flow like a movie script, i.e., be easy to visualize",
          "additionalInformation":[
             {
                "title":"Rationale",
                "description":[
                   "When you're being literal, your writing loses potency because there's nothing for the reader to visualize. Create a rich experience"
                ]
             },
             {
                "title":"Good example",
                "description":"The judge's whistle signaled the end of the game and also of our winning streak. I hugged my teammates without uttering a word"
             },
             {
                "title":"Bad example",
                "description":"I felt very sad after losing the soccer game. I couldn't hold back tears as I thought about us breaking the multi-week winning streak"
             }
          ],
          "cardId":"card_1",
          "score":4.0,
          "singleShow":false,
          "bucket":"B12"
       }
    ],
    "hashedName":"cd12aa72f86e8242272146293318d829",
    "score":{
       "grammar":100,
       "syntax":100,
       "structure":100,
       "flow":100,
       "overall":69.5
    },
    "percentile":65,
    "summary_img_link":"https://chckr.io/chckr/get_image/86b0ef9fd2f3e3a172ca070d90907ca0/cd12aa72f86e8242272146293318d829/dist.png",
    "is_essay":true,
    "summary":[
       "Your essay is engaging and personal, effectively showcasing your transformation and passion for plant-based living. The narrative is compelling, as it connects your personal experiences with broader themes of health, sustainability, and community involvement. Your enthusiasm is palpable, and your unique voice shines through, making the essay memorable.",
       "",
       "To enhance your essay, consider weaving in more reflection on how these experiences have shaped your personal growth and future aspirations. While your passion is clear, adding a layer of introspection about how this journey has influenced your values, decision-making, or perspective on life could provide a deeper insight into who you are. This reflection can help the reader understand not just what you have done, but why it matters to you and how it will influence your future endeavors.",
       "",
       "Top opportunities to improve:",
       "1. Add insights and takeaways",
       "2. Help the reader visualize your writing",
       "3. Build up to more specific language",
       "4. Tone down emotional language",
       "5. Cut out redundant words and phrases",
       "",
       "Top strengths: ",
       "1. One overarching idea throughout the essay",
       "2. No topic jumping",
       "3. Strong logical links between paragraphs",
       "4. Writing that reads effortlessly",
       "5. Avoiding cliches and generalizations"
    ],
    "detailed_summary":[
       "Your essay presents a compelling narrative that effectively captures your personal transformation and passion for a plant-based lifestyle. The story of your mother's cancer diagnosis serves as a powerful catalyst for change, providing a strong emotional anchor that draws the reader in. This personal connection not only humanizes your essay but also demonstrates your capacity for empathy and support, qualities that are highly valued in any applicant.",
       "One of the strengths of your essay is the vivid imagery and enthusiasm you convey when discussing your journey into nutritional science and sustainability. Your descriptions of learning about food pairings and the environmental impact of plant-based diets are engaging and informative. This demonstrates your ability to synthesize information and apply it to real-world situations, showcasing your intellectual curiosity and commitment to making informed choices.",
       "However, while your enthusiasm is palpable, the essay occasionally veers into territory that might be perceived as overly ambitious or lacking in focus. For instance, the mention of opening an oatmeal cafe, while creative, might seem tangential to the core narrative of your personal transformation and advocacy. It could be beneficial to streamline this section, perhaps by focusing more on your current efforts with the Northern Nevada PlantPure Pod and how these experiences have prepared you for future endeavors. This would help maintain a cohesive narrative thread throughout the essay.",
       "Additionally, while your passion for plant-based living is clear, the essay could benefit from a more balanced perspective. Acknowledging the challenges or initial skepticism you faced when transitioning to this lifestyle could add depth to your story. This would not only make your journey more relatable but also demonstrate resilience and adaptability, qualities that are crucial for success in any academic or professional setting.",
       "Your essay also touches on your leadership skills and community involvement, which are important aspects of your application. However, these points could be expanded upon to provide a clearer picture of your impact and the skills you've developed. For example, you could delve deeper into specific initiatives you've led within the PlantPure Pod or describe a particular challenge you overcame in this role. This would provide concrete evidence of your leadership abilities and commitment to your cause.",
       "In terms of writing style, your essay is generally well-written, but there are moments where the language could be refined for clarity and impact. For instance, the phrase \"make oatmeal the Beyonce of the breakfast world\" is catchy but might benefit from a more straightforward explanation of your vision for the cafe. Ensuring that your language is precise and purposeful will enhance the overall professionalism of your essay.",
       "Overall, your essay is a strong reflection of your passions and values. By refining certain sections for clarity and focus, and by providing a more nuanced perspective on your journey, you can create an even more compelling narrative that effectively showcases your unique qualities and potential contributions to your chosen field of study."
    ],
    "plagiarism":{
       "score":100.0,
       "description":[
          "You can check the plagiarism checkbox next time to check your essay for plagiarism."
       ],
       "link":null
    }
 }