import React, { useState, useEffect } from 'react'
import injectStyles from 'react-jss'

import EditorContainer from '../../../components/EditorContainer/container/EditorContainer'
import AccordionTabs from '../../../components/AccordionTabs/component/AccordionTabs'
import NavigBar from '../../../components/NavigBar/component/NavigBar'
import DemoView from '../component/DemoView'
import ModalSummary from '../../../components/ModalSummary/ModalSummary'
import ButtonsAboveTab from '../../../components/ButtonsAboveTab/component/ButtonsAboveTab'
import { styles } from '../../../components/AccordionTabs/styles'
import ErrorsTab from '../../../components/AccordionTabs/component/ErrorTab'
import { availableData, STATE_TABS } from '../../../constants'
import { handleChangeWorkOnNext, useWindowDimensions } from '../../../utils'
import { newEssay } from '../../../assets/new_demo'
import Switcher from '../../../components/Switcher/Switcher'
import SmallDevice from '../../../components/SmallDevice/SmallDevice'

const tabs = Object.values(
  newEssay.errors.reduce(
    (acc, item) => {
      const { type } = item
      acc[type] = {
        ...acc[type],
        id: type,
        count: (acc[type].count += 1),
      }
      return acc
    },
    {
      structure: {
        label: 'Structure',
        count: 0,
      },
      flow: {
        label: 'Flow',
        count: 0,
      },
      syntax: {
        label: 'Syntax',
        count: 0,
      },
      grammar: {
        label: 'Grammar',
        count: 0,
      },
    },
  ),
)

const Demo = ({ classes }) => {
  const { width } = useWindowDimensions()

  const [activeErrorData, setActiveErrorData] = useState({})
  const [isOpenModalSummary, setIsOpenModalSummary] = useState(false)
  const [activeCategory, setActiveCategory] = useState(null)
  const [isAllCategory, setAllCategory] = useState(true)
  const [hoveredTab, setHoveredTab] = useState(null)
  const [workOnNext, setWorkOnNext] = useState(null)
  const [filteredErrors, setFilteredErrors] = useState([])
  const [usefulHires, setUsefulHires] = useState([])
  const [hoveredCategoryId, setHoveredCategoryId] = useState(null)
  const [score, setScore] = useState({})

  const progress = 100
  const isLogin = true
  const disableEdit = true
  const receivedResult = newEssay.text
  const summary = newEssay.summary
  const isEssay = newEssay.is_essay
  console.log('demo lending');
  useEffect(() => {
    if (isAllCategory) {
      setActiveCategory(null)
      setWorkOnNext(null)
    }
  }, [isAllCategory])

  useEffect(() => {
    if (activeCategory) {
      setAllCategory(false)
    } else {
      setAllCategory(true)
    }
  }, [activeCategory])

  useEffect(() => {
    if (workOnNext) {
      setActiveCategory(workOnNext)
    }
  }, [workOnNext])

  useEffect(() => {
    const errorsAndDividedScore = newEssay.errors.reduce(
      (acc, error) => {
        usefulHires.forEach(hireId => {
          if (error.id === hireId) {
            acc[error.type] += error.score
            acc.overall += error.score
          }
        })
        return acc
      },
      { structure: 0, flow: 0, syntax: 0, grammar: 0, overall: 0 },
    )
    const staleScore = { ...newEssay.score }
    const newScore = Object.keys(staleScore).reduce((acc, type) => {
      if (type !== 'overall') {
        acc[type] += errorsAndDividedScore[type]
      } else {
        acc[type] += Math.round(errorsAndDividedScore[type] / 4)
      }
      return acc
    }, staleScore)
    setScore(newScore)
  }, [usefulHires.length])

  const setActiveErrorDataHandler = async newActiveErrorData => {
    if (
      !isLogin &&
      (newActiveErrorData.errorType !== availableData.type ||
        newActiveErrorData.errorId !== availableData.errorId)
    ) {
      return
    }
    if (newActiveErrorData.errorType) {
      setActiveCategory(newActiveErrorData.errorType)
    }
    if (newActiveErrorData.errorId === activeErrorData.errorId) {
      return setActiveErrorData({})
    }
    setActiveErrorData(newActiveErrorData)
  }

  const setActiveCategoryHandler = value => {
    setActiveErrorData({})
    setActiveCategory(value)
  }
  const dataLoaded = true

  useEffect(() => {
    const filteredErrors = newEssay.errors.reduce((acc, item) => {
      if (item.type === activeCategory) {
        acc.push(item)
      }
      return acc
    }, [])

    setFilteredErrors(filteredErrors)
  }, [activeCategory])

  const headerHoveredTab =
    isAllCategory && hoveredTab ? hoveredTab : activeCategory

  const handleChangeUsefulIds = id => {
    setUsefulHires(prev => {
      if (prev.includes(id)) {
        return prev.filter(item => item !== id)
      } else {
        return [...prev, id]
      }
    })
  }

  const handleChangeErrorData = panel => (event, isExpanded) => {
    setActiveErrorData(isExpanded ? { errorId: panel.id, origin: 'card' } : {})
  }
  const errorIfUserNotRegister = {}

  const onSubmitFeedback = async () => {}
  return (
    <>
      <NavigBar
        dataLoaded={dataLoaded}
        progress={progress}
        currentTab={activeCategory}
        tabs={tabs}
        hoveredTab={headerHoveredTab}
        stateTab={STATE_TABS.SUCCESSFUL_MODE}
        width={width}
        overall={score.overall}
        isEssay={isEssay}
        setDataSingleEssay={() => {}}
      />
      {width <= 756 ? (
        <SmallDevice />
      ) : (
        <DemoView>
          <ModalSummary
            isOpenModal={isOpenModalSummary}
            onCloseModal={() => setIsOpenModalSummary(false)}
            summary={summary}
            hashedName={newEssay.hashedName}
            overall={score.overall}
            isDemo={true}
            isEssay={isEssay}
          />
          <EditorContainer
            loading={false}
            receivedResult={receivedResult}
            activeErrorData={activeErrorData}
            setActiveErrorData={setActiveErrorDataHandler}
            activeCategory={activeCategory}
            isAllCategory={isAllCategory}
            hoveredTab={hoveredTab}
            usefulHires={usefulHires}
            hoveredCategoryId={hoveredCategoryId}
            onHoverCategory={setHoveredCategoryId}
            updateRawInput={() => {}}
          />
          <AccordionTabs
            stateTab={STATE_TABS.SUCCESSFUL_MODE}
            isDataLoaded={dataLoaded}
            progress={progress}
            tabs={tabs}
            onTabClick={setActiveCategoryHandler}
            onHoverTab={setHoveredTab}
            workOnNext={workOnNext}
            activeTab={activeCategory}
            errorIfUserNotRegister={errorIfUserNotRegister}
            isPaid={true}
            score={score}
            errors={
              <ErrorsTab
                handleChangeUsefulIds={id =>
                  handleChangeUsefulIds(id, setUsefulHires)
                }
                hoveredCategoryId={hoveredCategoryId}
                setChecked={() => {}}
                onHoverCategory={setHoveredCategoryId}
                filteredErrors={filteredErrors}
                handleChangeErrorData={handleChangeErrorData}
                usefulHires={usefulHires}
                activeErrorData={activeErrorData}
                classes={classes}
                disableEdit={disableEdit}
                setActiveErrorData={setActiveErrorData}
                isPaid={true}
                errorIfUserNotRegister={errorIfUserNotRegister}
                onSubmitFeedback={onSubmitFeedback}
              />
            }
            buttons={
              <ButtonsAboveTab
                isPaid={true}
                setAllCategory={setAllCategory}
                isAllCategory={isAllCategory}
                handleChangeWorkOnNext={() =>
                  handleChangeWorkOnNext(tabs, setWorkOnNext)
                }
                workOnNext={workOnNext}
                stateTab={STATE_TABS.SUCCESSFUL_MODE}
                setIsOpenModalSummary={setIsOpenModalSummary}
              />
            }
          />
        </DemoView>
      )}
    </>
  )
}

export default injectStyles(styles)(Demo)
